<template>
  <RegisterMaintenanceModal
    v-if="showModal"
    :selectedMaintenance="selectedMaintenance"
    @closeModal="closeModal"
  />
  <LoadingData v-if="isLoading" />
  <div v-else class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link :to="PATHS.HOME"
          ><i class="ph-caret-left"></i
        ></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-list-checks me-2" /> Garantias
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Garantias</h6>
          <p>Permite que você abra um chamado para acionar a garantia</p>
        </template>
      </Popper>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 d-flex justify-content-between">
          <p>Está com algum problema? entre em contato</p>
          <router-link
            class="btn btn-primary rounded-pill"
            :to="PATHS.SUPPORT_NEW_TICKET_WARRANTIES"
            >Abrir Chamado de Garantia</router-link
          >
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table text-nowrap">
        <tbody>
          <template v-for="(item, index) in formatedMaintenances" :key="index">
            <tr class="table-light">
              <td colspan="2">{{ item.name }}</td>
              <td class="text-end">
                <span :class="`badge bg-${item.class} rounded-pill`">{{
                  item.itens.length
                }}</span>
              </td>
            </tr>
            <tr v-for="(maintenances, id) in item.itens" :key="id">
              <td>
                <span class="text-body fw-semibold letter-icon-title"
                  >Período da Manutenção:</span
                >
                <div class="d-flex align-items-center text-muted fs-sm">
                  {{ maintenances.submitStart }} à
                  {{ maintenances.submitEnd }}
                </div>
              </td>
              <td>
                <div class="fw-semibold">
                  {{ maintenances.nameTask }}
                </div>
                <span class="text-muted">{{ maintenances.nameItem }}</span>
              </td>
              <td class="text-end">
                <div v-if="item.name === 'Aguardando Registro de Manutenção'">
                  <button
                    type="button"
                    @click="openModal(maintenances)"
                    class="btn btn-flat-success btn-icon rounded-pill"
                    title="Realizar apontamento da manutenção realizada"
                  >
                    <i class="ph-plus"></i>
                  </button>
                </div>
                <div v-if="item.name === 'Manutenções Futuras'">
                  <button
                    type="button"
                    class="btn btn-flat-primary btn-icon rounded-pill"
                    disabled
                  >
                    <i class="ph-clock"></i>
                  </button>
                </div>
                <div
                  class="d-flex justify-content-end"
                  v-if="item.name === 'Manutenção Registrada'"
                >
                  <div class="d-flex align-items-center">
                    <div class="me-2" :title="maintenances.observation">
                      <div class="text-body fw-semibold letter-icon-title">
                        Nº {{ maintenances.registerNumber }}
                      </div>
                      <div class="d-flex align-items-center text-muted fs-sm">
                        Data: {{ maintenances.maintenanceDate }}
                      </div>
                    </div>
                    <a
                      :href="`${apiUrl}${maintenances.uuid}/${maintenances.file}`"
                      class="btn btn-flat-success btn-icon rounded-pill"
                      title="Realizar Download do Item."
                      target="_blank"
                      download
                    >
                      <i class="ph-download-simple"></i>
                    </a>
                  </div>
                </div>
                <div v-if="item.name === 'Manutenção Não Realizada'">
                  <!-- <button
                    type="button"
                    class="btn btn-flat-danger btn-icon rounded-pill"
                    title="Não é possível registrar manutenção após o prazo."
                    disabled
                  >
                    <i class="ph-x"></i>
                  </button> -->
                  <button
                    type="button"
                    @click="openModal(maintenances)"
                    class="btn btn-flat-success btn-icon rounded-pill"
                    title="Realizar apontamento da manutenção realizada"
                  >
                    <i class="ph-plus"></i>
                  </button>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'
import LoadingData from '@/components/commons/LoadingData.vue'
import { dateNow } from '@/utils/date'
import RegisterMaintenanceModal from '@/components/warranties/RegisterMaintenanceModal.vue'

export default {
  name: 'PreventiveMaintenance',
  components: {
    LoadingData,
    RegisterMaintenanceModal,
  },
  data() {
    return {
      PATHS,
      isLoading: false,
      showModal: false,
      unity: this.$store.getters.unity,
      apiUrl: `${process.env.VUE_APP_BASE_URL_API}/documents/preventive-maintenance/`,
      formatedMaintenances: [],

      maintenanceStatus: [
        {
          name: 'Aguardando Registro de Manutenção',
          class: 'success',
          info: '',
        },
        {
          name: 'Manutenções Futuras',
          class: 'info',
          info: 'Aguarde até a data de liberação para registrar a manutenção',
        },
        {
          name: 'Manutenção Registrada',
          class: 'primary',
          info: 'Não foram registradas manutenções para as tarefas abaixo',
        },
        {
          name: 'Manutenção Não Realizada',
          class: 'danger',
          info: 'Não foram registradas manutenções para as tarefas abaixo',
        },
      ],
    }
  },
  methods: {
    openModal(maintenance) {
      this.selectedMaintenance = {
        constructiveSubSystemId: maintenance.constructiveSubSystemId,
        uuidUnity: maintenance.uuid,
        expectedDate: maintenance.submitExpected,
        executedDate: null,
        registerNumber: null,
        observation: '',
        file: null,
      }
      this.showModal = true
    },
    closeModal(needReload) {
      this.resetMaintenance()
      this.showModal = true
      if (needReload) {
        this.getPreventiveMaintenanceStatus()
      }
    },
    async getPreventiveMaintenanceStatus() {
      try {
        this.maintences = await this.$store.dispatch(
          'getPreventiveMaintenanceStatus',
          { unity: this.unity },
        )
        this.groupMaintenances()
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    groupMaintenances() {
      const arrayMaintences = []
      this.maintenanceStatus.forEach((status, index) => {
        arrayMaintences[index] = status
        arrayMaintences[index].itens = []
        this.maintences.forEach((maintence) => {
          if (maintence.status === status.name) {
            arrayMaintences[index].itens.push(maintence)
          }
        })
      })
      this.formatedMaintenances = arrayMaintences
    },
    resetMaintenance() {
      this.selectedMaintenance = {
        construtiveSubSystemId: null,
        uuidUnity: null,
        expectedDate: null,
        executedDate: null,
        registerNumber: null,
        observation: null,
        file: null,
      }
    },
  },
  mounted() {
    this.getPreventiveMaintenanceStatus()
    this.resetMaintenance()
  },
}
</script>
