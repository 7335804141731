<template>
  <div class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link :to="PATHS.SUPPORT"
          ><i class="ph-caret-left"></i
        ></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-question me-2" /> FAQ - Perguntas Frequentes
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Central de Ajuda</h6>
          <p>Exibe as principais perguntas e respostas de possíveis dúvidas</p>
        </template>
      </Popper>
    </div>
    <div class="card-body">
      <input
        type="search"
        class="form-control rounded-pill"
        placeholder="Buscar"
        v-model="search"
      />
    </div>
    <LoadingData v-if="isLoadingFaq" />
    <div v-else class="card-body">
      <div class="item" v-for="(item, index) in faqs" :key="index">
        <h6 class="fw-light text-primary mb-1">{{ item.question }}</h6>
        <p class="mb-3">{{ item.answer }}</p>
      </div>
      <div v-if="faqs.length === 0">
        <h6 class="fw-light text-primary mb-1 text-center">
          Nenhum item encontrado para o termo pesquisado.
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import Popper from 'vue3-popper'
import PATHS from '@/router/routesMap'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'Faq',
  components: {
    Popper,
    LoadingData,
  },
  data() {
    return {
      PATHS,
      faqs: [],
      isLoadingFaq: true,
      search: '',
    }
  },
  watch: {
    search() {
      this.getFaq()
    },
  },
  methods: {
    async getFaq() {
      try {
        this.isLoadingFaq = true
        this.faqs = await this.$store.dispatch('getFaq', {
          search: this.search,
        })
        this.isLoadingFaq = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getFaq()
  },
}
</script>
