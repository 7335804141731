<template>
  <div class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link :to="PATHS.SUPPORT_TICKET_LIST"
          ><i class="ph-caret-left"></i
        ></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-question me-2" /> Detalhes do Atendimento
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Central de Ajuda</h6>
          <p>Colocar instruções aqui</p>
        </template>
      </Popper>
    </div>
    <div class="card-body">
      <LoadingData v-if="isLoadingTicket" />
      <div v-else>
        <h6 class="fw-light">
          Informações do Atendimento Nº {{ ticket.ticketProtocol }}
        </h6>
        <div class="row">
          <div class="col-12">
            <p><b>Empreendimento:</b> {{ ticket.emprName }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p><b>Bloco/Unidade:</b> {{ ticket.unityName }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p><b>Apto/Subunidade:</b> {{ ticket.subUnityName }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              <b>Data de Abertura:</b>
              {{ dateTimeSqlToDateTimeBR(ticket.createdAt) }}
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              <b>Assunto:</b>
              {{ ticket.ticketSubjectName }}
            </p>
          </div>
          <div class="col-12 col-md-6" v-if="ticket.userResponsable">
            <p>
              <b>Responsável:</b>
              {{ ticket.userResponsable }}
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              <b>Status:</b>
              {{ ticket.status }}
            </p>
          </div>
          <div class="col-12">
            <hr />
          </div>
        </div>
        <div class="row" v-if="ticket.warrantyData">
          <div class="col-12">
            <h6 class="fw-light">Dados referentes à garantia:</h6>
          </div>

          <div class="col-12">
            <p>
              <b>Problema:</b>
              {{ ticket.warrantyData.problemName }}
            </p>
          </div>
          <div class="col-12 col-sm-6">
            <p>
              <b>Item com problema:</b>
              {{ ticket.warrantyData.itemName }}
            </p>
          </div>
          <div class="col-12 col-sm-6">
            <p>
              <b>Local do problema:</b>
              {{ ticket.warrantyData.localName }}
            </p>
          </div>
          <div class="col-12 col-sm-6">
            <p>
              <b>O problema está relacionado a:</b>
              {{ ticket.warrantyData.ticketRelatedBy }}
            </p>
          </div>
          <div class="col-12">
            <hr />
          </div>
        </div>
      </div>
      <LoadingData v-if="isLoadingInteractions" />
      <InteractionsTicket
        v-else
        :interactions="interactions"
        :uuid="uuid"
        :ticket="ticket"
        @getInteractions="getInteractions"
        @getTicketDetails="getTicketDetails"
      />
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'
import Popper from 'vue3-popper'
import LoadingData from '@/components/commons/LoadingData.vue'
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import InteractionsTicket from '@/components/supports/interactions/InteractionsTicket.vue'

export default {
  name: 'TicketDetails',
  components: {
    LoadingData,
    InteractionsTicket,
    Popper,
  },
  data() {
    return {
      PATHS,
      dateTimeSqlToDateTimeBR,
      uuid: this.$route.params.uuid,
      ticket: null,
      interactions: [],
      isLoadingTicket: true,
      isLoadingInteractions: true,
    }
  },
  methods: {
    async getTicketDetails() {
      try {
        this.isLoadingTicket = true
        this.ticket = await this.$store.dispatch('getTicketDetails', this.uuid)
        this.isLoadingTicket = false
        this.getInteractions()
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getInteractions() {
      try {
        this.isLoadingInteractions = true
        this.interactions = await this.$store.dispatch(
          'getTicketInteractions',
          this.uuid,
        )
        this.isLoadingInteractions = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  watch: {
    '$route.params.uuid': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.uuid = this.$route.params.uuid
          this.getTicketDetails()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getTicketDetails()
  },
}
</script>
