<template>
  <div class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link :to="PATHS.SUPPORT"
          ><i class="ph-caret-left"></i
        ></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-question me-2" /> Acionar Garantia
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Acionar Garantia</h6>
          <p>
            Permite verificar e solicitar reparos que se encontram na etapa de
            garantia
          </p>
        </template>
      </Popper>
    </div>
    <div class="card-body">
      <form @submit.prevent="openTicketWarranties">
        <div class="row">
          <div class="col-12 mb-3">
            <label for="warrantyProblemId"
              >Selecione o item com problema:</label
            >
            <select
              :disabled="isLoadingProblems"
              id="warrantyProblemId"
              class="form-select"
              :class="{ 'is-invalid': v$.selectedProblem.$errors.length }"
              placeholder="usuario@email.com"
              v-model="v$.selectedProblem.$model"
              @change="changeProblem()"
            >
              <option value="" selected disabled>Selecione um item</option>
              <option
                v-for="problem in problems"
                :key="problem.problemId"
                :value="problem"
              >
                {{ problem.itemName }} - {{ problem.problemName }}
              </option>
            </select>
            <span
              v-if="v$.selectedProblem.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
          </div>
          <div class="col-12 mb-3">
            <label for="warrantyLocalId"
              >Selecione o cômodo onde esta o problema:</label
            >
            <select
              :disabled="isLoadingLocals"
              id="warrantyLocalId"
              class="form-select"
              :class="{ 'is-invalid': v$.warrantyLocalId.$errors.length }"
              v-model="v$.warrantyLocalId.$model"
            >
              <option value="" selected disabled>Selecione um local</option>
              <option
                v-for="local in locals"
                :key="local.warrantyLocalId"
                :value="local.warrantyLocalId"
              >
                {{ local.warrantyLocalName }}
              </option>
            </select>
            <span
              v-if="v$.selectedProblem.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
          </div>
          <div class="col-12 mb-3">
            <label for="warrantyLocalId"
              >Deseja complementar com informações adicionais?</label
            >
            <textarea
              id="complementarInfo"
              class="form-control"
              v-model="complementarInfo"
            >
            </textarea>
          </div>
          <div class="col-12 mb-3">
            <label for="warrantyLocalId">O problema está relacionado a:</label>
            <select
              id="warrantyRelatedBy"
              class="form-select"
              :class="{ 'is-invalid': v$.warrantyRelatedBy.$errors.length }"
              v-model="v$.warrantyRelatedBy.$model"
            >
              <option value="" selected disabled>Selecione um item</option>
              <option value="Equipamento">Equipamento</option>
              <option value="Instalação">Instalação</option>
            </select>
            <span
              v-if="v$.warrantyRelatedBy.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
          </div>
          <div class="col-12 mb-3">
            <label for="warrantyLocalId">Anexo:</label>
            <input
              type="file"
              id="attachment"
              @change="onFilePicked"
              class="form-control"
              v-on="attachment"
            />
          </div>
          <div class="col-12 mt-2">
            <div class="d-flex justify-content-between">
              <div>
                <div v-if="!isLoadingWarrantyStatus">
                  <p v-html="warrantyStatusMessage"></p>
                </div>
              </div>
              <button
                class="btn btn-primary rounded-pill"
                :disabled="
                  v$.$invalid || disableButton || warrantyStatusDisplay
                "
              >
                <span v-if="disableButton">
                  <i class="ph-spinner"></i> Registrando ...
                </span>
                <span v-else>Cadastrar</span>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div
        class="card card-body bg-yellow d-none"
        v-if="!isLoadingWarrantyStatus"
      >
        <h6>Status da Garantia</h6>
        <div class="row">
          <div class="col-12 col-md-6">
            <p>
              Status da Garantia por Data:
              {{ warrantyStatus.warrantyDateStatus }}
            </p>
          </div>

          <div class="col-12 col-md-6">
            <p>
              Status da Garantia por Manutenção Preventiva:
              {{ warrantyStatus.warrantyMaintenanceStatus }}
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              Início da Garantia:
              {{ warrantyStatus.warrantyStart }}
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              Término da Garantia:
              {{ warrantyStatus.warrantyFinish }} ({{
                warrantyStatus.daysOfWarranty
              }}
              dias)
            </p>
          </div>
          <div class="col-12 table-responsive">
            <table class="table table-striped table-xs">
              <thead>
                <tr>
                  <th>Tarefa</th>
                  <th>Data Prevista</th>
                  <th>Status</th>
                  <th>Executada Em</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, key) in warrantyStatus.maintenanceList"
                  :key="key"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.executedAt }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import Popper from 'vue3-popper'
import PATHS from '@/router/routesMap'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'NewTicketWarranty',
  components: {
    Popper,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      selectedProblem: {
        required,
      },
      warrantyLocalId: {
        required,
      },

      warrantyRelatedBy: { required },
    }
  },
  data() {
    return {
      PATHS,
      disableButton: false,
      isLoadingProblems: true,
      isLoadingLocals: true,
      isLoadingWarrantyStatus: true,
      unity: this.$store.getters.unity,
      selectedProblem: null,
      warrantyItemId: null,
      warrantyProblemId: null,
      warrantyLocalId: null,
      warrantyGlobalSystemId: null,
      warrantyRelatedBy: null,
      complementarInfo: '',
      attachment: null,
      problems: [],
      locals: [],
      warrantyStatus: null,
      file: null,
      warrantyStatusMessage: '',
      warrantyStatusDisplay: false,
    }
  },
  methods: {
    validateWarranty() {
      if (this.warrantyStatus.warrantyDateStatus === 'Expirada') {
        this.warrantyStatusMessage = `Garantia deste item está expirada. Válido até ${this.warrantyStatus.warrantyFinish}. Não é possível abrir um atendimento.`
        this.warrantyStatusDisplay = true
      } else if (this.warrantyStatus.warrantyMaintenanceStatus === 'Expirada') {
        const notDo = this.warrantyStatus.maintenanceList.filter(
          (item) => item.status === 'Não Realizada',
        )
        this.warrantyStatusMessage = `Item sem garantia devido não realização de uma manutenção preventiva.<br> Manutenção não realizada: ${notDo[0].name} em ${notDo[0].date}. Não é possível abrir um atendimento.`
        this.warrantyStatusDisplay = true
      }
      // this.warrantyStatusDisplay = false // Só para testes
    },
    async getProblemsWarranties() {
      try {
        this.problems = await this.$store.dispatch(
          'getProblemsWarranties',
          this.unity,
        )
        this.isLoadingProblems = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getlocalsWarranties() {
      try {
        this.locals = await this.$store.dispatch(
          'getlocalsWarranties',
          this.warrantyItemId,
        )
        this.isLoadingLocals = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async verifyWarranty() {
      try {
        this.warrantyStatus = await this.$store.dispatch('verifyWarranty', {
          unity: this.unity,
          warrantyItemId: this.warrantyItemId,
        })
        this.validateWarranty()
        this.isLoadingWarrantyStatus = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    onFilePicked(event) {
      const { files } = event.target
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      const [fileuploaded] = files
      this.file = fileuploaded
    },
    async openTicketWarranties() {
      try {
        this.disableButton = true
        const formData = new FormData()
        formData.append('codPessEmpr', this.unity.codPessEmpr)
        formData.append('numUnity', this.unity.numUnity)
        formData.append('numSubUnity', this.unity.numSubUnity)
        formData.append('item', this.warrantyItemId)
        formData.append('problem', this.warrantyProblemId)
        formData.append('local', this.warrantyLocalId)
        formData.append('related', this.warrantyRelatedBy)
        formData.append('complementarInfo', this.complementarInfo)
        formData.append('file', this.file)
        const response = await this.$store.dispatch(
          'openTicketWarranties',
          formData,
        )
        this.$root.$refs.notification.success(response.message)
        this.$router.push({
          name: 'TicketDetails',
          params: { uuid: response.data },
        })
        this.disableButton = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    changeProblem() {
      this.warrantyItemId = this.selectedProblem.warrantyItemId
      this.warrantyProblemId = this.selectedProblem.problemId
      this.warrantyLocalId = null
      this.getlocalsWarranties()
      this.verifyWarranty()
    },
  },
  mounted() {
    this.getProblemsWarranties()
  },
}
</script>
