<template>
  <div>
    <div class="card">
      <div
        class="card-header d-flex align-items-center justify-content-between py-0"
      >
        <div>
          <router-link to="/"><i class="ph-caret-left"></i></router-link>
        </div>
        <h6 class="py-3 mb-0 text-secondary">
          <i class="ph-files me-2" /> Notícias
        </h6>
        <Popper>
          <button type="button" class="btn btn-link">
            <i class="ph-info ms-2"></i>
          </button>
          <template #content>
            <h6>Boletos em Aberto</h6>
            <p>
              Exibe informativos sobre o andamento da obra com fotos e evoluções
              e notícias diversas
            </p>
          </template>
        </Popper>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12" :class="evolutions ? 'col-md-8' : 'col-md-12'">
            <div class="row">
              <div class="col-12">
                <h5 class="fw-light">Por Dentro da Obra</h5>
              </div>
              <div v-if="isLoadingConstruction" class="col-12">
                <LoadingData />
              </div>
              <div
                v-else
                class="col-12 col-sm-6 col-md-3"
                v-for="construction in constructions"
                :key="construction.ncUuid"
              >
                <router-link :to="`/news/constructions/${construction.ncUuid}`">
                  <div class="card bg-primary text-white">
                    <div class="card-body">
                      <h6 class="fw-light text-center mb-0">
                        {{ construction.month }}/{{ construction.year }}
                      </h6>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <router-link :to="`/news/constructions`">
                  <div class="card border-primary">
                    <div class="card-body">
                      <h6 class="fw-light text-center mb-0 text-primary">
                        Ver Todos
                      </h6>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h5 class="fw-light">Informativos</h5>
              </div>
              <div v-if="isLoadingInformative" class="col-12">
                <LoadingData />
              </div>
              <div
                v-else
                class="col-12"
                v-for="informative in informatives"
                :key="informative.niUuid"
              >
                <router-link :to="`/news/informatives/${informative.niUuid}`">
                  <div class="">
                    <div class="">
                      <h6 class="fw-light mb-0">
                        {{ informative.title }}
                      </h6>
                    </div>
                  </div>
                </router-link>
                <hr class="my-1" />
              </div>
              <div class="col-12">
                <router-link :to="`/news/informatives`">
                  <h6 class="fw-light mb-0 text-primary">Ver Todos</h6>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-4" v-if="evolutions">
            <div class="row">
              <div class="col-12">
                <h5 class="fw-light">Evolução da Obra</h5>
              </div>
              <div class="col-12">
                <ConstructionEvolution :evolutions="evolutions" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import ConstructionEvolution from '@/components/news/ConstructionEvolution.vue'

export default {
  name: 'NewsHome',

  components: {
    LoadingData,
    ConstructionEvolution,
  },

  data() {
    return {
      unity: this.$store.getters.unity,
      isLoadingEvolution: true,
      evolutions: [],
      isLoadingInformative: true,
      informatives: [],
      isLoadingConstruction: true,
      constructions: [],
    }
  },

  methods: {
    async getEvolution() {
      try {
        this.isLoadingEvolution = true
        this.evolutions = await this.$store.dispatch('getEvolution', this.unity)
        this.isLoadingEvolution = true
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getInformatives() {
      try {
        this.isLoadingInformative = true
        this.informatives = await this.$store.dispatch('getInformatives', {
          unity: this.unity.codPessEmpr,
          limit: 3,
          offset: 0,
        })
        this.isLoadingInformative = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getConstructions() {
      try {
        this.isLoadingConstruction = true
        this.constructions = await this.$store.dispatch('getConstruction', {
          unity: this.unity.codPessEmpr,
          limit: 3,
          offset: 0,
        })
        this.isLoadingConstruction = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getEvolution()
    this.getInformatives()
    this.getConstructions()
  },
}
</script>
