<template>
  <div>
    <div class="card">
      <div
        class="card-header d-flex align-items-center justify-content-between py-0"
      >
        <div>
          <router-link to="/news"><i class="ph-caret-left"></i></router-link>
        </div>
        <h6 class="py-3 mb-0 text-secondary">
          <i class="ph-files me-2" /> Informativos
        </h6>
        <Popper>
          <button type="button" class="btn btn-link">
            <i class="ph-info ms-2"></i>
          </button>
          <template #content>
            <h6>Boletos em Aberto</h6>
            <p>
              Aqui são listados os boletos em aberto com a SN, é possível copiar
              o código de barras e também realizar o download dos boletos em
              PDF.
            </p>
          </template>
        </Popper>
      </div>
      <div class="card-body">
        <div class="row">
          <div
            class="col-12"
            v-for="informative in informatives"
            :key="informative.niUuid"
          >
            <router-link :to="`/news/informatives/${informative.niUuid}`">
              <div class="card bg-primary text-white">
                <div class="card-body py-1">
                  <div class="d-flex justify-content-between">
                    <div class="title">
                      <h6 class="fw-light mb-0">
                        {{ informative.title }}
                      </h6>
                      <p class="mb-0">{{ informative.description }}</p>
                    </div>
                    <div class="date">
                      <p>
                        {{ dateTimeSqlToDateTimeBR(informative.createdAt) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div v-if="isLoadingInformatives" class="col-12">
            <LoadingData />
          </div>
          <div v-else class="col-12">
            <button
              class="card border-primary w-100 p-2"
              @click="getInformatives()"
            >
              <h6 class="fw-light mb-0 text-center text-primary">
                Carregar Mais
              </h6>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import LoadingData from '@/components/commons/LoadingData.vue'
import Popper from 'vue3-popper'

export default {
  name: 'NewsInformativesList',

  components: {
    LoadingData,
    Popper,
  },

  data() {
    return {
      dateTimeSqlToDateTimeBR,
      unity: this.$store.getters.unity,
      isLoadingInformatives: true,

      informatives: [],
      limit: 10,
      offset: 0,
    }
  },

  methods: {
    async getInformatives() {
      try {
        this.isLoadingInformatives = true
        const data = await this.$store.dispatch('getInformatives', {
          unity: this.unity.codPessEmpr,
          limit: this.limit,
          offset: this.offset,
        })

        this.offset += this.limit
        this.informatives.push(...data)
        this.isLoadingInformatives = false

        if (data.length === 0) {
          this.$root.$refs.notification.warning(
            'Não há mais registros publicados.',
          )
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getInformatives()
  },
}
</script>
