<template>
  <div class="">
    <div class="" v-for="item in evolutions" :key="item.id">
      <div class="d-flex mb-3">
        <div class="me-3">
          <div
            class="bg-primary bg-opacity-10 text-primary lh-1 rounded-pill p-2"
          >
            <i :class="`flaticon-${item.icon}`"></i>
          </div>
        </div>
        <div class="flex-fill">
          {{ item.name }} ({{ item.percent }}%)
          <div class="text-muted fs-sm">
            <div class="progress">
              <div
                class="progress-bar"
                :style="`width: ${item.percent}%`"
                :aria-valuenow="item.percent"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/fonts/flat/flaticon.css'

export default {
  name: 'ConstructionEvolution',

  props: {
    evolutions: {
      type: Array,
      required: true,
    },
  },
  components: {},

  data() {
    return {
      disableButton: false,
    }
  },

  methods: {},

  mounted() {},
}
</script>
