<template>
  <div class="">
    <div class="media-chat-scrollable mb-3">
      <div class="media-chat vstack gap-3">
        <template v-for="interaction in interactions" :key="interaction.tiId">
          <InteractionsChat :interaction="interaction" />
        </template>
      </div>
    </div>
    <div id="interaction-form" v-if="ticket.ticketStatusId !== 'F'">
      <textarea
        class="form-control form-control-content mb-3"
        placeholder="Digite uma nova mensagem..."
        v-model="message"
      ></textarea>
      <DropFile v-if="showFileInput" @updateFiles="updateFiles" />
      <div class="d-flex align-items-center">
        <div>
          <button
            href="#"
            class="btn btn-light btn-icon border-transparent rounded-pill btn-sm me-1"
            data-bs-popup="tooltip"
            title="Enviar arquivo"
            @click="showFileInput = !showFileInput"
          >
            <i class="ph-paperclip"></i>
          </button>
        </div>

        <button
          type="button"
          class="btn btn-primary ms-auto"
          @click="sendMessage"
          :disabled="disableButton"
        >
          Enviar Mensagem
          <i class="ph-paper-plane-tilt ms-2"></i>
        </button>
      </div>
    </div>
    <div v-else-if="ticket.ticketAvaliationRating === null">
      <div class="text-center">
        <hr />
        <h5 class="fw-light">
          De 0 a 10, como você avalia o nosso atendimento?
        </h5>
        <div class="btn-groups mb-2">
          <template v-for="index in 10" :key="index">
            <input
              type="radio"
              class="btn-check"
              name="options"
              :id="`option_${index}`"
              autocomplete="off"
              :value="index"
              v-model="ticketAvaliationRating"
            />
            <label
              class="btn btn-outline-secondary my-1 me-2"
              :for="`option_${index}`"
              >{{ index }}</label
            >
          </template>
        </div>
        <h5 class="fw-light">
          Deseja deixar algum comentário sobre o nosso atendimento?
        </h5>
        <textarea
          class="form-control form-control-content mb-3"
          placeholder="Digite uma nova mensagem..."
          v-model="ticketAvaliationMessage"
        ></textarea>
        <button
          type="button"
          class="btn btn-primary ms-auto"
          @click="doAvaliation"
          :disabled="disableButton"
        >
          Avaliar Atendimento
        </button>
      </div>
    </div>
  </div>
</template>
<style>
.media-chat-scrollable {
  max-height: 22.5rem;
}
</style>
<script>
import Popper from 'vue3-popper'
import InteractionsChat from '@/components/supports/interactions/InteractionsChat.vue'
import DropFile from '@/components/commons/DropFile.vue'

export default {
  name: 'InteractionsTicket',

  props: {
    uuid: {
      type: String,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
    interactions: {
      type: Array,
      required: true,
    },
  },
  components: {
    InteractionsChat,
    DropFile,
  },

  data() {
    return {
      disableButton: false,
      message: '',
      files: [],
      showFileInput: false,
      ticketAvaliationRating: null,
      ticketAvaliationMessage: '',
    }
  },

  methods: {
    updateFiles(files) {
      this.files = files
    },
    async sendMessage() {
      try {
        if (this.message === '' && this.files.length === 0) {
          this.$root.$refs.notification.warning(
            'Digite uma mensagem antes de enviar.',
          )
          return
        }
        const filesInternal = []
        if (this.files.length > 0) {
          const numberOfFiles = this.files.length
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < numberOfFiles; i++) {
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
              this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(this.files[i])
            const [fileuploaded] = this.files

            const formData = new FormData()
            formData.append('uuid', this.uuid)
            formData.append('files', fileuploaded)
            this.disableButton = true
            // eslint-disable-next-line no-await-in-loop
            const response = await this.$store.dispatch('sendTicketMessage', {
              uuid: this.uuid,
              formData,
            })
          }
        }
        const formData = new FormData()
        formData.append('uuid', this.uuid)
        formData.append('message', this.message)
        formData.append('files', null)
        this.disableButton = true
        // eslint-disable-next-line no-await-in-loop
        const response = await this.$store.dispatch('sendTicketMessage', {
          uuid: this.uuid,
          formData,
        })

        if (response.status) {
          this.$root.$refs.notification.success(response.message)
          this.message = ''
          this.files = []
          this.showFileInput = false
          this.$emit('getInteracrions')
        }
        this.disableButton = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },
    async doAvaliation() {
      try {
        if (this.ticketAvaliationRating === null) {
          this.$root.$refs.notification.warning(
            'Selecione uma nota antes de clicar em enviar.',
          )
          return
        }
        this.disableButton = true
        // eslint-disable-next-line no-await-in-loop
        const response = await this.$store.dispatch('sendTicketAvaliation', {
          uuid: this.uuid,
          ticketAvaliationRating: this.ticketAvaliationRating,
          ticketAvaliationMessage: this.ticketAvaliationMessage,
        })
        if (response.status) {
          this.$root.$refs.notification.success(response.message)
          this.$emit('getTicketDetails')
        }
        this.disableButton = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },
  },

  mounted() {},
}
</script>
