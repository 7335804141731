<template>
  <div class="main">
    <div
      class="dropzone-container"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        multiple
        name="file"
        id="fileInput"
        class="hidden-input"
        @change="onChange"
        ref="file"
      />

      <label for="fileInput" class="file-label">
        <div v-if="isDragging">Solte os arquivos aqui.</div>
        <div v-else>
          Arraste os arquivos aqui ou <u>clique aqui</u> para enviar os
          arquivos.
        </div>
      </label>

      <div
        class="preview-container d-flex flex-wrap align-items-center mt-4"
        v-if="files.length"
      >
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div>
            <div class="preview-img" v-html="generateThumbnail(file)"></div>
            <!-- <img class="preview-img" :src="generateThumbnail(file)" /> -->
            <p :title="file.name">
              {{ makeName(file.name) }}
            </p>
          </div>
          <div class="btn-container">
            <button
              class="btn btn-close badge rounded-pill text-bg-danger"
              type="button"
              @click="remove(files.indexOf(file))"
              title="Remover Arquivo"
            >
              <i class="ph-x ph-xs"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropFile',
  data() {
    return {
      isDragging: false,
      files: [],
    }
  },
  methods: {
    onChange() {
      this.files = [...this.$refs.file.files]
      this.$emit('updateFiles', this.files)
    },
    generateThumbnail(file) {
      if (file.type.includes('image')) {
        const fileSrc = URL.createObjectURL(file)

        setTimeout(() => {
          URL.revokeObjectURL(fileSrc)
        }, 1000)
        return `<img src="${fileSrc}" />`
      }
      if (file.type.includes('pdf')) {
        return `<i class="ph-file-pdf" style="font-size: 8.25rem "></i>`
      }
      if (file.type.includes('doc')) {
        return `<i class="ph-microsoft-word-logo" style="font-size: 8.25rem "></i>`
      }
      if (file.type.includes('zip')) {
        return `<i class="ph-file-zip" style="font-size: 8.25rem "></i>`
      }
      return `<i class="ph-file" style="font-size: 8.25rem "></i>`
    },
    makeName(name) {
      return name.length < 12
        ? name
        : `${name.split('.')[0].substring(0, 15)}...${
            name.split('.')[name.split('.').length - 1]
          }`
    },
    remove(i) {
      this.files.splice(i, 1)
    },
    dragover(e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave() {
      this.isDragging = false
    },
    drop(e) {
      e.preventDefault()
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      this.isDragging = false
    },
  },
}
</script>

<style>
.main {
  display: flex;
  text-align: center;
  margin-bottom: 2rem;
}
.dropzone-container {
  width: 160%;
  padding: 3rem;
  background: #f7fafc;
  border: 1px solid #e2e8f0;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  display: block;
  cursor: pointer;
}
.preview-container {
  display: flex;
  margin-top: 2rem;
}
.preview-card {
  display: flex;
  min-width: 160px;
  border: 1px solid #a2a2a2;
  padding: 5px;
  margin-left: 5px;
}
.preview-img {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
.preview-img > img {
  max-width: 100%;
  max-height: 150px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
.ph-xs {
  font-size: 1rem;
}
.btn-container {
  display: flex;
  position: relative;
}
.btn-close {
  position: absolute;
  width: 23px;
  height: 23px;
  left: -25px;
  top: -5px;
}
</style>
