<template>
  <div
    class="media-chat-item"
    :class="interaction.tiUserType === 'C' ? ' media-chat-item-reverse' : ''"
  >
    <div class="media-chat-message">
      <div class="fs-sm lh-sm">
        <span class="fw-semibold">{{ interaction.createdBy }}</span>
        <span class="opacity-50 ms-2">{{
          dateTimeSqlToDateTimeBR(interaction.createdAt)
        }}</span>
      </div>
      <span v-if="interaction.tiType === 'M'">
        <span>{{ interaction.tiMessage }}</span>
      </span>
      <span v-else-if="interaction.tiType === 'A'">
        <div class="" v-if="typeAttachment === 'image'">
          <img :src="filePath" class="img-fluid w-100" />
          <button
            class="btn btn-yellow rounded-pill btn-sm mt-1"
            @click="doDownload(filePath)"
          >
            <i class="ph-download-simple me-2"></i>
            Fazer Download
          </button>
        </div>
        <div class="" v-else>
          <button
            class="btn btn-yellow rounded-pill btn-sm mt-1"
            @click="doDownload(filePath)"
          >
            <i class="ph-download-simple me-2"></i>
            Fazer Download
          </button>
        </div>
      </span>
      <span v-else-if="interaction.tiType === 'O'">
        <InteractionsOption
          :interaction="interaction"
          :options="interaction.tiMessage"
        />
      </span>
      <span v-else-if="interaction.tiType === 'WQ'"></span>
    </div>
  </div>
</template>
<script>
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import InteractionsOption from './InteractionsOption.vue'

export default {
  name: 'InteractionsChat',

  props: {
    interaction: {
      type: Object,
      required: true,
    },
  },
  components: {
    InteractionsOption,
  },

  data() {
    return {
      filePath: `${process.env.VUE_APP_BASE_URL_API}/documents/tickets/${this.interaction.tiTicketUuid}/${this.interaction.tiId}/${this.interaction.tiFile}`,
      dateTimeSqlToDateTimeBR,
      typeAttachment: null,
      extensionAttachment: null,
      imageExtensions: ['jpg', 'jpeg', 'png', 'gif', 'svg'],
      pdfExtensions: ['pdf'],
    }
  },

  methods: {
    getExtensionFile() {
      if (!this.interaction.tiFile) {
        this.extensionAttachment = null
      } else {
        this.extensionAttachment = this.interaction.tiFile.split('.').pop()
      }
      this.verificationExtension()
    },
    verificationExtension() {
      if (this.imageExtensions.includes(this.extensionAttachment)) {
        this.typeAttachment = 'image'
      } else if (this.pdfExtensions.includes(this.extensionAttachment)) {
        this.typeAttachment = 'pdf'
      } else {
        this.typeAttachment = 'file'
      }
    },
    doDownload() {
      window.open(this.filePath, '_blank')
    },
  },

  mounted() {
    this.getExtensionFile()
  },
}
</script>
